import random from 'lodash/random'

const a = 97
const z = 122
export default (word = 's', match = /[a-zA-Z]/) =>
  word.split('')
    .map((ch) => {
      if (match.test(ch)) return String.fromCharCode(random(a, z))
      return ch
    })
    .join('')