import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import AtIcon from 'react-icons/lib/fa/at'
import GithubIcon from 'react-icons/lib/fa/github'
import WrenchIcon from 'react-icons/lib/fa/wrench'
import PencilIcon from 'react-icons/lib/fa/pencil'

import * as colors from './colors'

export const widthLg = '25%'
export const widthSm = '60px'
export const transition = '250ms'

const Container = styled.div`
  width: ${widthLg};
  height: 100%;
  background-color: ${colors.white};
  box-shadow: 1px 0px 5px ${colors.darkBlue};
  position: absolute;
  left: 0px;
  padding: 1.45rem 1.0875rem;
  transition: width ${transition};

  @media only screen and (max-width: 640px) {
    width: ${widthSm};
    padding: 0.45rem 0px;
  }
`

const Logo = styled.div`
  height: 150px;
  width: 150px;
  margin: 1.5rem auto;
  background-color: ${colors.brightRed};
  border-radius: 150px;
  transition: width ${transition}, height ${transition};

  @media only screen and (max-width: 960px) {
    height: 100px;
    width: 100px;
  }

  @media only screen and (max-width: 640px) {
    height: 40px;
    width: 40px;
  }
`

const LinkText = styled.span`
  @media only screen and (max-width: 640px) {
    display: none;
  }
`

const Links = styled.div`
  font-family: monospace;
  margin: 3rem auto 0;
  padding-left: 10%;
  max-width: 200px;
 
  a {
    display: block;
    margin-top: 1rem;
  }

  svg {
    font-size: 2em;
  }

  @media only screen and (max-width: 640px) {
    text-align: center;
    a {
      margin: 2rem 0;
    }
  }
`

const SideBar = () =>
  <Container>
    <Link to="/">
      <Logo />
    </Link>
    <Links>
      <Link to="/blog/">
        <PencilIcon />&nbsp;
        <LinkText>Blog</LinkText>
      </Link>
      <Link to="/projects/">
        <WrenchIcon />&nbsp;
        <LinkText>Projects</LinkText>
      </Link>
      <a href="https://github.com/sethbonnie">
        <GithubIcon />&nbsp;
        <LinkText>Github</LinkText>
      </a>
      <Link to="/contact">
        <AtIcon />&nbsp;
        <LinkText>Contact</LinkText>
      </Link>
    </Links>
  </Container>

export default SideBar
