import React from 'react'

import Scramble from './Scramble'

const blankWord = () => ''

export default ({ children }) => (
  <Scramble scrambler={blankWord}>
    {children}
  </Scramble>
)
