import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import * as colors from './colors'
import SideBar, * as sidebar from './SideBar'
import './index.css'
import 'prismjs/themes/prism.css'

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: ${sidebar.widthLg};
  overflow: hidden;
  transition: padding-left ${sidebar.transition};

  h1, h2 {
    font-family: 'Anonymous Pro', monospace;
    margin-top: 1.5rem;
  }
  h3 {
    font-family: 'Cutive Mono', monospace;
    font-weight: 800;
  }
  
  a {
    color: ${colors.lightBlue};
    text-decoration: none;
  }
  a:visited {
    color: ${colors.lightBlueDarker};
  }

  @media only screen and (max-width: 640px) {
    padding-left: ${sidebar.widthSm};
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background-color: ${colors.blueGray};
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.45rem 2.0875rem;
  margin: 1.5rem auto;
  overflow-y: auto;
`

const Centered = styled.div`
  max-width: 640px;
  margin: 0px auto;
  padding-bottom: 2em;
`

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'sethbonnie.com' },
            { name: 'keywords', content: 'programming, engineering, discipline, circa20xx' },
          ]}
        />
        <SideBar />
        <Content>
          <Centered>
            {children}
          </Centered>
        </Content>
      </Container>
    )}
  />
)
