import React, { Component } from 'react'
import range from 'lodash/range'

import { randomWord } from '../helpers'

const scrambleInterval = 50

const textContent = (children) => {
  if (typeof children === 'string') {
    return children;
  }
  if (children && children.props) {
    if (children.props.children) {
      return textContent(children.props.children);
    }
    return children;
  }
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  throw new Error('Invalid type passed to textContent()')
}

class Scramble extends Component {
  state = {
    index: 0,
    word: textContent(this.props.children),
    timeoutId: null,
  }

  componentDidMount() {
    const { word } = this.state
    const timeoutId = setInterval(() => {
      if (this.state.index > this.state.word.length) {
        clearInterval(timeoutId)
      }
      this.setState(previous => ({ index: previous.index + 1 }))
    }, word.length > 30 ? scrambleInterval - 10 : scrambleInterval)
    this.setState({ timeoutId })
  }
  componentWillUnmount() {
    clearInterval(this.state.timeoutId)
  }
  
  render() {
    const { index, word } = this.state
    const { match, scrambler } = this.props
    if (index === 0) return <span>{range(0, word.length).map((x, i) => <span key={i}>&nbsp;</span>)}</span>;
    return (
      <span>
        <span>{word.substr(0, index)}</span>
        <span>{scrambler(word.substr(index), match)}</span>
      </span>
    )
  }
}

Scramble.defaultProps = {
  scrambler: randomWord
}

export default Scramble
